import React, { lazy, Suspense } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeAffinitySuggestions } from 'website/actions/AffinitySuggestionsActions';
import { Dispatch, State } from 'website/reducers';
import { Entity, SuggestableEntity } from 'website/types';

type Props = {
  entityId: string;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({ closeAffinitySuggestions }, dispatch);
};

const mapStateToProps = (state: State, ownProps: Props) => {
  let suggestionItems: Entity[] = [];
  let open = false;

  const suggestionBlock =
    state.affinitySuggestions.suggestionsBlocks[ownProps.entityId];

  if (suggestionBlock) {
    suggestionItems = suggestionBlock.items.map(
      id => state.data.all[id] as SuggestableEntity
    );
    open = suggestionBlock.open;
  }

  return {
    entity: state.data.all[ownProps.entityId] as SuggestableEntity,
    suggestions: suggestionItems,
    open: open,
    user: state.user
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

const AffinitySuggestions = lazy(() => import('./AffinitySuggestions'));

const LazyAffinitySuggestions = ({
  user,
  suggestions = [],
  entity,
  open = false,
  closeAffinitySuggestions
}: PropsFromRedux) => {
  // Shoud we show the UI ?
  if (!entity || !user.loggedIn || !suggestions || suggestions.length < 3) {
    return null;
  }
  // Then let's load it only now
  return (
    <Suspense fallback="...">
      <AffinitySuggestions
        open={open}
        suggestions={suggestions}
        entity={entity}
        closeAffinitySuggestions={closeAffinitySuggestions}
      />
    </Suspense>
  );
};

export default connector(LazyAffinitySuggestions);
