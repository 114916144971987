import React from 'react';
import { createRoot } from 'react-dom/client';

import {
  isSize,
  isTheme,
  isType,
  Size,
  Theme,
  Type
} from 'common/components/Button';
import {
  isSocialActionPlacements,
  SocialActionPlacements
} from 'common/constants/trackingEventsNames';
import readAttribute from 'common/tools/dom/readAttribute';
import trans from 'common/tools/translations/trans';

import wantToSeeBoosterHoc from 'website/components/Tooltip/wantToSeeBooster';
import WantToSeeButton, {
  WantToSeeButtonProps
} from 'website/components/WantToSeeButton';
import allocineContainer from 'website/containers/allocineContainer';

export default function () {
  const wantToSeeButtons = document.getElementsByClassName(
    'js-user-want-to-see'
  );

  if (wantToSeeButtons.length) {
    for (const button of wantToSeeButtons) {
      const entityId = readAttribute<string, undefined>(
        button,
        'data-entity-id'
      );
      if (entityId) {
        const classes = readAttribute<string, undefined>(
          button,
          'data-classes'
        );
        const size = readAttribute<Size, undefined>(button, 'data-size');
        const type = readAttribute<Type, undefined>(button, 'data-type');
        const theme = readAttribute<Theme, undefined>(button, 'data-theme');
        const content = readAttribute<string, undefined>(
          button,
          'data-content'
        );
        const socialActionPlacement =
          readAttribute<SocialActionPlacements, undefined>(
            button,
            'data-social-action-placement'
          ) || 'standalone';

        const ConnectedWantToSeeButton =
          allocineContainer<WantToSeeButtonProps>(
            wantToSeeBoosterHoc(
              WantToSeeButton,
              button,
              'js-tooltip-want-to-see-booster',
              <span>{trans('tooltip-booster.want-to-see')}</span>
            )
          );

        const root = createRoot(button);
        root.render(
          <ConnectedWantToSeeButton
            entityId={entityId}
            className={classes}
            size={isSize(size) ? size : undefined}
            type={isType(type) ? type : undefined}
            theme={isTheme(theme) ? theme : undefined}
            socialActionPlacement={
              isSocialActionPlacements(socialActionPlacement)
                ? socialActionPlacement
                : undefined
            }
            withSuggestions
          >
            {content}
          </ConnectedWantToSeeButton>
        );
      }
    }
  }
}
