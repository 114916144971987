import React from 'react';

import BaseTooltip from 'common/components/Tooltip';
import BaseClickOutTooltip from 'common/components/Tooltip/ClickOutTooltip';
import BaseCloseButtonTooltip from 'common/components/Tooltip/CloseButtonTooltip';
import BaseHoverTooltip from 'common/components/Tooltip/HoverTooltip';

/**
 * This HoC will set constraintRectangle and constraintHeight
 * properties of the WrappedComponent to website DOM
 * elements ids
 *
 * @param {Component} WrappedComponent a component
 * @returns {Component} a component
 */

const constraintHeightClass = 'header-main';

function websiteScopedHoc(WrappedComponent) {
  return props => (
    <WrappedComponent
      {...props}
      constraintRectangle="content-layout"
      constraintHeight={constraintHeightClass}
    />
  );
}

const Tooltip = websiteScopedHoc(BaseTooltip);

export const HoverTooltip = websiteScopedHoc(BaseHoverTooltip);
export const ClickOutTooltip = websiteScopedHoc(BaseClickOutTooltip);
export const CloseButtonTooltip = websiteScopedHoc(BaseCloseButtonTooltip);
export default Tooltip;
