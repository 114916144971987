import roller from 'common/module/ui/roller';

import applicationBootstrap from 'website/bootstrap';
import { socialActionsBootstrap } from 'website/bootstrap/socialActionsBootstrap';
import userRating from 'website/containers/user-action/userRating';
import userWantToSee from 'website/containers/user-action/userWantToSee';
import affinitySuggestions from 'website/containers/user/affinitySuggestions';
import facebookWidgets from 'website/module/ui/facebookWidgets';
import thirdNav from 'website/module/ui/third-nav';

applicationBootstrap().then(() => {
  facebookWidgets();
  thirdNav();
  roller();

  // Social modules based on Redux
  userRating();
  userWantToSee();
  socialActionsBootstrap();
  affinitySuggestions();
});
