import {
  addUserOpinion,
  deleteUserOpinion,
  updateUserOpinion,
  getHelpfulUnhelpfulForReviews
} from 'common/api/GraphApi';

import * as actionTypes from 'website/constants/ActionTypes';

/**
 * Async action : delete an existing opinion
 * @param  {Object} opinion the opinion to delete
 * @param  {Object} entity the entity related to the opinion
 * @param  {Object} context - tracking data to fetch
 */
export const deleteOpinion = (
  opinion: { rating: number | null; review?: string | null },
  entity: { id: string },
  context?: any
) => ({
  types: [
    actionTypes.DELETE_OPINION_REQUEST,
    actionTypes.DELETE_OPINION_SUCCESS,
    actionTypes.DELETE_OPINION_FAILURE
  ],
  callAPI: () => deleteUserOpinion(entity.id, { ...context, opinion }),
  payload: { opinion, entity }
});

/**
 * Async action : update an existing opinion
 * @param  {Object} opinion the opinion with its updated values
 * @param  {Object} entity the entity related to the opinion
 * @param  {Number} previousValue the previous value
 * @param  {Object} context - tracking data to fetch
 */
export const updateOpinion = (
  opinion: { rating: number | null; review?: string | null },
  entity: { id: string },
  previousValue?: string | number,
  context?: any
) => ({
  types: [
    actionTypes.UPDATE_OPINION_REQUEST,
    actionTypes.UPDATE_OPINION_SUCCESS,
    actionTypes.UPDATE_OPINION_FAILURE
  ],
  callAPI: () =>
    updateUserOpinion(entity.id, opinion.rating, opinion.review, {
      ...context,
      opinion
    }),
  payload: { opinion, entity, previousValue }
});

/**
 * Async action : create a new opinion
 *
 * @param  {Object} opinion opinion object
 * @param  {Object} entity the entity related to the opinion
 * @param  {Object} context - tracking data to fetch
 */
export const createOpinion = (
  opinion: { rating: number | null; review?: string | null },
  entity: { id: string },
  context?: any
) => ({
  types: [
    actionTypes.CREATE_OPINION_REQUEST,
    actionTypes.CREATE_OPINION_SUCCESS,
    actionTypes.CREATE_OPINION_FAILURE
  ],
  callAPI: () =>
    addUserOpinion(entity.id, opinion.rating, opinion.review, {
      ...context,
      opinion
    }),
  payload: {
    opinion,
    entity,
    transactionId: `createOpinion:${Math.floor(Math.random() * -100000)}`
  }
});

export const getOpinionOnReviews = (opinionIds: string[]) => ({
  types: [
    actionTypes.OPINION_ACTIONS_RETRIEVAL_REQUEST,
    actionTypes.OPINION_ACTIONS_RETRIEVAL_SUCCESS,
    actionTypes.OPINION_ACTIONS_RETRIEVAL_FAILURE
  ],
  callAPI: () => getHelpfulUnhelpfulForReviews(opinionIds),
  payload: {}
});
