import React from 'react';
import { createRoot } from 'react-dom/client';

import readAttribute from 'common/tools/dom/readAttribute';

import LazyAffinitySuggestions from 'website/components/suggestions/LazyAffinitySuggestions';
import allocineContainer from 'website/containers/allocineContainer';

export default function affinitySuggestions() {
  const anchors = document.getElementsByClassName('js-affinity-suggestions');

  const ConnectedAffinitySuggestions = allocineContainer(
    LazyAffinitySuggestions
  );

  if (anchors.length) {
    for (const anchor of anchors) {
      const entityId = readAttribute<string, undefined>(
        anchor,
        'data-entity-id'
      );
      if (entityId) {
        const root = createRoot(anchor);
        root.render(<ConnectedAffinitySuggestions entityId={entityId} />);
      }
    }
  }
}
