import React, { FC, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import tooltipBoosterHoc, {
  TooltipBoosterHocProps
} from 'website/components/Tooltip/tooltipBooster';
import { DataState } from 'website/reducers/data';
import { getWantToSeeForEntity } from 'website/reducers/data/helper';

type PropsFromRedux = ConnectedProps<typeof connector>;

type ComponentProps = TooltipBoosterHocProps &
  PropsFromRedux & {
    active: boolean;
  };

// prevents the display of the tooltip if we disable the button want-to-see
let hideWantToSeeBooster = false;

const mapStateToProps = (
  state: { data: DataState },
  ownProps: { entityId: string }
) => ({
  active: !!getWantToSeeForEntity(ownProps.entityId, state.data)
});

const connector = connect(mapStateToProps);

/**
 * this HOC will define the display
 * conditions of the tooltip booster for the
 * want-to-see button section only
 *
 * @param {Component} WrappedComponent  a component
 * @param {Element}   anchor            dom anchor of wrapped component
 * @param {String}    boosterClass      className to display tooltip
 * @param {Component} BoosterContent    tooltip content
 * @returns {Component} a component
 */
const wantToSeeBoosterHoc = (
  WrappedComponent: FC<Omit<ComponentProps, 'active'>>,
  anchor: Element,
  boosterClass: string,
  BoosterContent: ReactNode
) =>
  connector(({ active = false, ...rest }: ComponentProps) => {
    // detects if the tooltip is already hidden or if the want-to-see button is active
    if (hideWantToSeeBooster || active) {
      // prevents the display of the tooltip if we disable the button want-to-see
      hideWantToSeeBooster = true;
      // displays the button without the tooltip booster hoc
      return <WrappedComponent {...rest} />;
    }

    const TooltipBoosterComponent = tooltipBoosterHoc(
      WrappedComponent,
      anchor,
      boosterClass,
      BoosterContent
    );

    return <TooltipBoosterComponent {...rest} />;
  });

export default wantToSeeBoosterHoc;
