import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Button, { Theme, Type, Size } from 'common/components/Button';
import {
  SocialActionPlacements,
  TrackingContext
} from 'common/constants/trackingEventsNames';
import noop from 'common/tools/noop';

import {
  closeAffinitySuggestions as _closeAffinitySuggestions,
  getAffinitySuggestions as _getAffinitySuggestions
} from 'website/actions/AffinitySuggestionsActions';
import {
  addWantToSee,
  deleteWantToSee
} from 'website/actions/SocialActionActions';
import AuthenticatedLink from 'website/components/user/AuthenticatedLink';
import { Dispatch, State } from 'website/reducers';
import {
  getOpinionForEntity,
  getWantToSeeForEntity
} from 'website/reducers/data/helper';
import { Entity, isSuggestableEntity } from 'website/types';

export type WantToSeeButtonProps = {
  children?: ReactNode;
  className?: string;
  entityId: string;
  size?: Size;
  socialActionPlacement?: SocialActionPlacements;
  theme?: Theme;
  type?: Type;
  withSuggestions?: boolean;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeAffinitySuggestions: (entity: Entity): void => {
      if (isSuggestableEntity(entity)) {
        dispatch(_closeAffinitySuggestions(entity));
      }
    },
    getAffinitySuggestions: (entity: Entity): void => {
      if (isSuggestableEntity(entity)) {
        dispatch(_getAffinitySuggestions(entity));
      }
    },
    toggleWantToSee: (
      wantsToSee: boolean,
      entity: Entity,
      trackingContext: TrackingContext
    ): void => {
      if (wantsToSee) {
        dispatch(addWantToSee(entity, trackingContext));
      } else {
        dispatch(deleteWantToSee(entity, trackingContext));
      }
    }
  };
};

const mapStateToProps = (state: State, ownProps: WantToSeeButtonProps) => {
  const active = !!getWantToSeeForEntity(ownProps.entityId, state.data);
  const disabled = !!getOpinionForEntity(ownProps.entityId, state.data);

  const affinitySuggestions =
    state?.affinitySuggestions?.suggestionsBlocks[ownProps.entityId];
  const suggestionsOpen = affinitySuggestions
    ? affinitySuggestions.open
    : false;

  return {
    active: active,
    disabled: disabled,
    entity: state?.data?.all[ownProps.entityId] as Entity,
    suggestionsOpen: suggestionsOpen,
    userId: state?.user?.account?.id
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const WantToSeeButton = ({
  active = false,
  children,
  className,
  closeAffinitySuggestions = noop,
  disabled = false,
  entity,
  getAffinitySuggestions = noop,
  size,
  socialActionPlacement = 'standalone',
  suggestionsOpen = false,
  theme,
  toggleWantToSee,
  type,
  userId,
  withSuggestions = false
}: WantToSeeButtonProps & PropsFromRedux) => {
  if (!entity) return null;
  const handleClick = async () => {
    if (withSuggestions) {
      // get the current state before toggle
      if (active && suggestionsOpen) {
        // if the current state is active
        // the next state will close the suggestions
        closeAffinitySuggestions(entity);
      }

      if (!active && !suggestionsOpen) {
        // if the current state is inactive
        // the next state will open the suggestions
        getAffinitySuggestions(entity);
      }
    }

    const trackingContext = {
      entity: entity,
      userId: userId,
      socialActionPlacement: socialActionPlacement
    };

    toggleWantToSee(!active, entity, trackingContext);
  };

  const buttonClasses = classNames(className, {
    active: active
  });

  return (
    <AuthenticatedLink>
      <Button
        onClick={handleClick}
        theme={theme}
        type={type}
        size={size}
        className={buttonClasses}
        disabled={disabled}
      >
        {children}
      </Button>
    </AuthenticatedLink>
  );
};

export default connector(WantToSeeButton);
