import {
  addWantToSee as _addWantToSee,
  deleteWantToSee as _deleteWantToSee,
  getUserSocialActionsForEntities as _getUserSocialActionsForEntities,
  createOpinionOnReview as _createOpinionOnReview,
  deleteOpinionOnReview as _deleteOpinionOnReview,
  markAsSeen as _markAsSeen,
  unmarkAsSeen as _unmarkAsSeen
} from 'common/api/GraphApi';

import {
  CREATE_SOCIAL_ACTION_REQUEST,
  CREATE_SOCIAL_ACTION_SUCCESS,
  CREATE_SOCIAL_ACTION_FAILURE,
  DELETE_SOCIAL_ACTION_REQUEST,
  DELETE_SOCIAL_ACTION_SUCCESS,
  DELETE_SOCIAL_ACTION_FAILURE,
  GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_REQUEST,
  GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_SUCCESS,
  GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_FAILURE
} from 'website/constants/ActionTypes';

export const addWantToSee = (entity, context) => ({
  types: [
    CREATE_SOCIAL_ACTION_REQUEST,
    CREATE_SOCIAL_ACTION_SUCCESS,
    CREATE_SOCIAL_ACTION_FAILURE
  ],
  callAPI: () => _addWantToSee(entity.id, context),
  payload: {
    socialActionTypename: 'WantToSee',
    transactionId: `addWantToSee:${Math.floor(Math.random() * -100000)}`,
    entity
  }
});

export const deleteWantToSee = (entity, context) => ({
  types: [
    DELETE_SOCIAL_ACTION_REQUEST,
    DELETE_SOCIAL_ACTION_SUCCESS,
    DELETE_SOCIAL_ACTION_FAILURE
  ],
  callAPI: () => _deleteWantToSee(entity.id, context),
  payload: {
    socialActionTypename: 'WantToSee',
    entity
  }
});

export const createOpinionOnReview = (entity, type) => {
  return {
    types: [
      CREATE_SOCIAL_ACTION_REQUEST,
      CREATE_SOCIAL_ACTION_SUCCESS,
      CREATE_SOCIAL_ACTION_FAILURE
    ],
    callAPI: () => _createOpinionOnReview(entity.id, type),
    payload: {
      socialActionTypename: type === 'helpful' ? 'Helpful' : 'Unhelpful',
      transactionId: `createOpinionOnReview:${Math.floor(
        Math.random() * -100000
      )}`,
      entity
    }
  };
};

export const deleteOpinionOnReview = (entity, type) => {
  return {
    types: [
      DELETE_SOCIAL_ACTION_REQUEST,
      DELETE_SOCIAL_ACTION_SUCCESS,
      DELETE_SOCIAL_ACTION_FAILURE
    ],
    callAPI: () => _deleteOpinionOnReview(entity.id, type),
    payload: {
      socialActionTypename: type === 'helpful' ? 'Helpful' : 'Unhelpful',
      transactionId: `deleteOpinionOnReview:${Math.floor(
        Math.random() * -100000
      )}`,
      entity
    }
  };
};

export const getUserSocialActionsForEntities = entityIds => {
  return {
    types: [
      GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_REQUEST,
      GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_SUCCESS,
      GET_USER_SOCIAL_ACTIONS_FOR_ENTITIES_FAILURE
    ],
    callAPI: () => _getUserSocialActionsForEntities(entityIds),
    payload: { entityIds }
  };
};

export const markAsSeen = (entity, context) => ({
  types: [
    CREATE_SOCIAL_ACTION_REQUEST,
    CREATE_SOCIAL_ACTION_SUCCESS,
    CREATE_SOCIAL_ACTION_FAILURE
  ],
  callAPI: () => _markAsSeen(entity.id, context),
  payload: { socialActionTypename: 'SeenIt', entity }
});

export const unmarkAsSeen = (entity, context) => ({
  types: [
    DELETE_SOCIAL_ACTION_REQUEST,
    DELETE_SOCIAL_ACTION_SUCCESS,
    DELETE_SOCIAL_ACTION_FAILURE
  ],
  callAPI: () => _unmarkAsSeen(entity.id, context),
  payload: { socialActionTypename: 'SeenIt', entity }
});
